import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0076 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="短時間正社員制度（時短正社員）の概要は？期待される人材、メリットデメリットについて解説！" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2024.11.08</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        一般的に「正社員」とは各々の会社で最も所定労働時間の長い労働者と定義されていることが多いでしょう。また、所定労働時間だけでなく、責任の程度や担うべき業務の範囲も広範囲であること、転勤の可能性があること等があり、有期契約労働者等とは異なる雇用形態であることは明らかです。ただし、働き方改革によって多様な働き方が尊重される時代背景が到来し、「より正社員に近い」働き方として、短時間正社員制度の導入が注目されています。今回は短時間正社員制度の概要や短時間正社員として期待される人材、導入にあたってのメリット・デメリットにフォーカスをあて、解説します。
      </p>
      <img src="/images/column/details/c0076.jpg" alt="キンクラコラム画像76" />
      <div id="toc1">
        <h1>制度の概要</h1>
      </div>
      <p>
        短時間正社員とは、厳密には企業によって定義は異なるものの、一般的には通常の正社員よりも働くべき時間が短いものの、
        <span>正社員と同等レベルの業務を担う社員</span>
        を指します。よって、正社員と全く働く時間が同じ場合、定義に当てはまらないとも言えます。注意点として、正社員と比較し、所定労働時間が一定程度短い労働者であるものの、期間の定めがある場合は有期契約労働者となりますので、定義からは外れると考えられます。すなわち、無期雇用労働者と解釈することとなります。また、基本給や賞与等の算定方法が正社員と同等程度であることも必要な要素となります。もちろん、社会保険適用事業所であれば、労働契約上、1週間の所定労働時間と1か月の所定労働日数が正社員の4分の3以上の場合は社会保険加入義務が生じますので、短時間正社員の場合は（正社員と比べてもそこまで大幅に短時間勤務となるとは想定し難いため）社会保険は適用対象となります。その点も踏まえて制度設計が必要となります。
      </p>

      <div id="toc2">
        <h1>期待される人材</h1>
      </div>
      <p>
        昨今、育児や介護問題と並行して働かざるを得ない労働者が増えており、有能なスキルを備えていながらもこれらの問題に阻まれ、退職または非正規雇用労働者としての労務提供になるというケースは少なくありません。しかし、多くの企業で「人手不足問題」が解消されておらず、有能な労働者には少しでも長く働いてもらうことで新たな採用にかかる時間的または費用的なコストは節約したいはずです。
        <br />
        <br />
        そこで、家庭の事情等により、有能なスキルを持っていながらも長い時間働けないという悩みを抱えていても、正社員と同様の待遇で迎え入れる体制があることで労働者目線ではインセンティブを感じてもらいながら企業としても有能な労働者からの労務提供を受領できる体制を構築できることとなります。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <div id="toc3">
        <h1>短時間正社員制度のメリット</h1>
      </div>
      <p>
        短時間正社員制度を導入することで、
        <span>
          家庭の事情で長時間働くことができないものの有能なスキルを持つ労働者との雇用契約の維持が可能
        </span>
        となることです。働き方改革以後、雇用の流動化が促進され始め、各企業ともに有能な労働者の雇用維持には頭を悩ませており、有能な労働者ほど、多くの企業から声がかかることが多いと言えます。短時間正社員制度の導入によって、インセンティブを感じてもらいながら雇用契約も維持することができれば企業と労働者双方にとってメリットがあると言えます。
        <br />
        <br />
        また、雇用期間の定めがない「無期雇用労働者」となるため、有期雇用労働者にある「契約の更新」がありません。契約更新時は労使双方で新たな雇用契約締結に向けた労働条件の見直し等の為に面談を設定することから、一定の時間をとられてしまいますので、
        <span>時間的なコスト削減も可能</span>
        となります。これは労使双方にとってメリットと言えます。
      </p>

      <div id="toc4">
        <h1>短時間正社員制度のデメリット</h1>
      </div>
      <p>
        最大の懸念点は制度に対する懐疑的な意見を示す層への説明が必要となることです。働く時間が短くても正社員と同等の待遇を受けられるとなれば、制度的には理解できても感情的には理解できないという反対層が出てきます。基本給では不就労分の差があってもその他の部分で正社員と同等の待遇となれば、一部の正社員から制度に対して懐疑的な意見が出ることもあるでしょう。この場合、会社としての必要性を示し、また、短時間正社員制度に関わらず、新たな制度を導入した場合、いきなり誰もが納得できる制度として運用できるケースは稀ですので、
        <span>懇切丁寧に会社としての方針を示していくことが有用</span>です。
        <br />
        <br />
        また、制度導入に対して一部の反対層の意見が多く、複数回にわたって制度の改正に舵を切った場合に、制度自体が形骸化してしまう恐れがあることです。例えば短時間正社員の賞与額のカット等を始め、有期契約労働者と実質的に変わらない制度となってしまってはせっかく導入した制度も形骸化してしまうこととなります。
      </p>

      <div id="toc5">
        <h1>最後に</h1>
      </div>
      <p>
        短時間正社員制度は各企業によって正解は異なり、また、導入してからも制度が形骸化しないよう制度のブラッシュアップが必要です。特に、多くの労働判例が出されている同一労働同一賃金問題や、各企業の個別的な問題が出てきた際には、その都度、検討を加えることが重要です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0076
